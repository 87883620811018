<template>
  <b-card class="blog-edit-wrapper">
    <!-- form -->
    <div class="text-center">

      
 
      <h4 class="mt-5">
        Self learning Course
        <feather-icon icon="Edit2Icon" />
      </h4>
    </div>
    <validation-observer ref="addCourseFormvalidate">
      <b-form
        ref="form"
        class="mt-2"
        style="width: 100%"
        @submit.prevent="save"
      >
        <b-row>

          <b-col md="6">
            <b-form-group
              label="Self learning Course"
              label-for="blog-edit-category"
              class="mb-2"
            >
              <validation-provider
                #default="{ errors }"
                name="Course"
                rules="required"
              >

                <v-select
                data-testid="recorded-course-select"
                  v-model="addCourseForm.recorded_course_id"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  label="name"
                  :options="coursesList"
                  :reduce="(val) => val.id"
                  @input="getDuration(addCourseForm.recorded_course_id)"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col md="6">
            <b-form-group
              label="In-Link Name"
              label-for="blog-edit-slug"
              class="mb-2"
            >
              <validation-provider
                #default="{ errors }"
                name="In-Link Name"
                rules="required"
              >
                <b-form-input
                data-testid="recorded-course-link"
                  v-model="addCourseForm.link_id"
                  @input="removeSpace($event)"
                />
                <small class="text-danger">{{ errors[0] }}</small>

                link after creation:{{
                  `https://lpcentre.com/online/self-learning/${addCourseForm.link_id}`
                }}
              </validation-provider>
            </b-form-group>
          </b-col>
                <b-col md="6">
            <b-form-group
              label="Also Avaliable Type"
              label-for="blog-edit-category"
              class="mb-2"
            >
              <validation-provider
                #default="{ errors }"
                name="Also Avaliable Type"
                rules=""
              >

                <v-select
                data-testid="recorded-course-type"
                  v-model="addCourseForm.also_avaliable_type"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  label="name"
                  :options="[{
                    name:'Classic',
                    id:1
                  },{
                    name:'Online',
                    id:2
                  },
                  {
                    name:'Dedicated',
                    id:3
                  }]"
                  :reduce="(val) => val.id"
                  @input="getType(addCourseForm.also_avaliable_type)"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
              <b-col md="6">
            <b-form-group
              label="Also Avaliable Course"
              label-for="blog-edit-category"
              class="mb-2"
            >
              <validation-provider
                #default="{ errors }"
                name="Also Avaliable Course"
                rules=""
              >

                <v-select
                  id="blog-edit-category"
                  v-model="addCourseForm.also_avaliable_id"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  label="name"
                  :options="coursesListType"
                  :reduce="(val) => val.id"
                 
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
              <b-col md="6">
            <b-form-group
              label="Category"
              label-for="blog-edit-category"
              class="mb-2"
            >
              <validation-provider
                #default="{ errors }"
                name="Category"
                rules="required"
              >
                <v-select
                data-testid="recorded-course-category"
                  v-model="addCourseForm.category_id"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  label="type"
                  :options="categoriesList"
                  :reduce="(val) => val.id"
                  @input="getCoursesByCategory(addCourseForm.category_id)"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
               <b-col md="6">
            <b-form-group
              label="Related Courses"
              label-for="blog-edit-course"
              class="mb-2"
            >
              <validation-provider
                #default="{ errors }"
                name="Courses"
                rules=""
              >
                <v-select
                data-testid="recorded-course-related"
                  v-model="addCourseForm.related_courses"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  label="name"
                  multiple
                  :options="RelatedCourse"

                  :reduce="(val) => val.id"
                >
                  <template #no-options="{ search, searching, loading }">
                    No data ...
                  </template>
                </v-select>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group
              label="Price"
              label-for="blog-edit-slug"
              class="mb-2"
            >
              <validation-provider
                #default="{ errors }"
                name="price"
                rules="required"
              >
                <b-form-input
                data-testid="recorded-course-price"
                  v-model="addCourseForm.price"
                  type="number"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <!-- <b-col md="6">
            <label class="flex">Coming soon </label>
              <b-form-radio-group
              v-model="addCourseForm.coming_soon"
              label="coming soon"
              class="mb-2"
            >
              <b-form-radio value="1">
                on
              </b-form-radio>
              <b-form-radio value="0">
                off
              </b-form-radio>
            </b-form-radio-group>
              
             
          </b-col> -->
          <b-col md="6"> 
            <b-form-group
              label="Status"
              label-for="blog-edit-category"
              class="mb-2"
            >
              <validation-provider
                #default="{ errors }"
                name="Status"
                rules=""
              >
                <v-select
                v-model="addCourseForm.coming_soon"  
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  label="text"
                  :options="  
        [  
          { value: null, text: 'Select Status' },  
          { value: 'available', text: 'Available' },  
          { value: 'coming_soon', text: 'Coming Soon' }  
        ]  
      " 
                  :reduce="(val) => val.value"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group> 
  
</b-col>
          <b-col md="12">
            <div class="border rounded p-2">
              <h4 class="mb-1">
                Header image
              </h4>
              <b-media
                no-body
                vertical-align="center"
                class="flex-column flex-md-row"
              >
                <div class="d-inline-block">
                  <b-media-aside>
                    <b-img
                      ref="refPreviewEl"
                      :src="addCourseForm.header_image ? `https://lpcentre.com/${addCourseForm.header_image}` : Tabimage"
                      height="110"
                      width="170"
                      class="rounded mr-2 mb-1 mb-md-0"
                    />
                  </b-media-aside>
                  <b-button
                    v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                    variant="outline-secondary"
                    class="rounded ml-1 mt-2 text-center"
                    @click="refPreviewEl.src = Tabimage
                            addCourseForm.header_image=''
                    "
                  >
                    Remove Image
                  </b-button>
                </div>
                <b-media-body>
                  <b-card-text class="mt-1" />
                  <div class="d-inline-block  mt-1">
                    <b-form-group
                      label="Header Image "
                      label-for="blog-edit-title"
                      class="mb-2"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="Header Image "
                        
                      >
                        <b-form-file
                        data-testid="recorded-course-header-image"
                          ref="refInputEl"
                          v-model="addCourseForm.header_image"
                          accept=".jpg, .png, .gif, .jpeg"
                          placeholder="Choose file"
                          @input="inputImageRenderer"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                    <b-form-group
                      label="Header image Alt Text"
                      label-for="blog-edit-title"
                      class="mb-2"
                    >
                      <b-form-input
                        id=" alt_header_image"
                        v-model="addCourseForm.alt_header_image"
                      />
                    </b-form-group>
                  </div>
                </b-media-body>
              </b-media>
            </div>
          </b-col>
            <b-col md="12">
            <div class="border rounded p-2">
              <h4 class="mb-1">
                Stripe image
              </h4>
              <b-media
                no-body
                vertical-align="center"
                class="flex-column flex-md-row"
              >
 
                <div class="d-inline-block">
                  <b-media-aside>
                    <b-img
                      ref="refPreviewE3"
                      :src="addCourseForm.stripe_image ? `https://lpcentre.com/${addCourseForm.stripe_image}` : Tabimage"
                      height="110"
                      width="170"
                      class="rounded mr-2 mb-1 mb-md-0"
                    />
                  </b-media-aside>
                  <b-button
                    v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                    variant="outline-secondary"
                    class="rounded ml-1 mt-2 text-center"
                    @click="refPreviewE3.src = Tabimage
                            addCourseForm.stripe_image=''
                    "
                  >
                    Remove Image
                  </b-button>
                </div>
                <b-media-body>
                  <b-card-text class="mt-1" />
                  <div class="d-inline-block  mt-1">
                    <b-form-group
                      label="Stripe Image "
                      label-for="blog-edit-title"
                      class="mb-2"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="stripeImage "
                        
                      >
                        <b-form-file
                        data-testid="recorded-course-stripe-image"
                          ref="refInputE3"
                          v-model="addCourseForm.stripe_image"
                          accept=".jpg, .png, .gif, .jpeg"
                          placeholder="Choose file"
                          @input="inputImageRendererTab3"
                        />
                         <label class="text"> Image must be 200x200 pixels</label>
                       
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  
                  </div>
                </b-media-body>
              </b-media>
            </div>
          </b-col>
          <b-col
            cols="12"
            class="mb-2"
          >
            <div class="border rounded p-2">
              <h4 class="mb-1">
                OG image
              </h4>
              <b-media
                no-body
                vertical-align="center"
                class="flex-column flex-md-row"
              >
                <div class="d-inline-block">
                  <b-media-aside>
                    <b-img
                      ref="refPreviewE2"
                      :src="addCourseForm.og_image ? `https://lpcentre.com/${addCourseForm.og_image}` : Tabimage"
                      height="110"
                      width="170"
                      class="rounded mr-2 mb-1 mb-md-0"
                    />
                  </b-media-aside>

                  <b-button
                    v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                    variant="outline-secondary"
                    class="rounded ml-1 mt-2 text-center"
                    @click="
                      refPreviewE2.src = Tabimage;
                      addCourseForm.og_image = '';
                    "
                  >
                    Remove Image
                  </b-button>
                </div>
                <b-media-body>
                  <b-card-text class="mt-1" />
                  <div class="d-inline-block mt-1">
                    <b-form-group
                      label="Tab Image "
                      label-for="blog-edit-name"
                      class="mb-2"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="Tab Image "
                      >
                        <b-form-file
                        data-testid="recorded-course-og-image"
                          ref="refInputE2"
                          v-model="addCourseForm.og_image"
                          accept=".jpg, .png, .gif, .jpeg"
                          placeholder="Choose file"
                          @input="inputImageRendererTab"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                    <!-- <b-form-group
                      label="Tab Image Alt Text"
                      label-for="blog-edit-name"
                      class="mb-2"
                    >
                      <b-form-input
                        id="alt-image"
                        v-model="addCourseForm.alt_og_image"
                      />
                    </b-form-group> -->
                  </div>
                </b-media-body>
              </b-media>
            </div>
          </b-col>
          <b-col
            cols="12"
            class=""
          >
            <b-form-group
              label="Description"
              label-for="blog-content"
              class="mb-2"
            >
              <validation-provider
                #default="{ errors }"
                name="Description"
                rules="required"
              >
                 <quill-editor
                  ref="descriptionEditor"
                       v-model="addCourseForm.description"
                  :options="getEditorOptions('descriptionEditor')"
                />
               
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group
              label="Objectives"
              label-for="blog-content"
              class="mb-2"
            >
              <validation-provider
                #default="{ errors }"
                name="objectives"
                rules="required"
              >
              <quill-editor
                  ref="objectivesEditor"
                       v-model="addCourseForm.objectives"
                  :options="getEditorOptions('objectivesEditor')"
                />
             
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col cols="12">
            <b-form-group
              label="Who should attend?"
              label-for="blog-content"
              class="mb-2"
            >
              <validation-provider
                #default="{ errors }"
                name="Description"
                rules=""
              >
                <quill-editor
                  ref="attendersEditor"
                       v-model="addCourseForm.attenders"
                  :options="getEditorOptions('attendersEditor')"
                />
            
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
            <b-col cols="12">
            <b-form-group
              label="Outlines"
              label-for="blog-content"
              class="mb-2"
            >
              <validation-provider
                #default="{ errors }"
                name="Description"
                rules=""
              >
                <quill-editor
                  ref="outlinesEditor"
                       v-model="addCourseForm.outlines"
                  :options="getEditorOptions('outlinesEditor')"
                />
            
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-col md="4">
              <b-form-group
                label=" Meta Title"
                label-for="blog-edit-title"
                class="mb-2"
              >
                <validation-provider
                  #default="{ errors }"
                  name="meta_title"
                  rules=""
                >
                  <b-form-input
                  data-testid="recorded-meta-title"
                    v-model="addCourseForm.meta_title"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col md="4">
              <b-form-group
                label="Meta Description"
                label-for="blog-edit-slug"
                class="mb-2"
              >

                <validation-provider
                  #default="{ errors }"
                  name="In-Link Name"
                  rules=""
                >
                  <b-form-input
                  data-testid="recorded-meta-description"
                    v-model="addCourseForm.meta_description"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col cols="4">
              <b-form-group
                label=" Meta Keywords"
                label-for="blog-content"
                class="mb-2"
              >

                <b-form-tags
                data-testid="recorded-meta-keyword"
                 v-model="addCourseForm.meta_keywords" />

              </b-form-group>
            </b-col>
          </b-col>

          <b-col
            cols="12"
            class="mt-50"
          >
            <b-button
            data-testid="recorded-submit-button"
              v-if="!isLoading"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-1"
              @click="save"
            >
              Save Changes
            </b-button>

            <b-button
              v-if="isLoading"
              variant="primary"
              disabled
              class="mr-1"
            >
              Loading...
              <b-spinner small />
              <span class="sr-only" />
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
    <!--/ form -->
      <b-modal id="image-properties-modal" title="Image Properties">
      <b-form>
        <b-form-group label="Alt Text">
          <b-form-input v-model="imageProperties.alt"></b-form-input>
        </b-form-group>
        <b-form-group label="Width">
          <b-form-input v-model="imageProperties.width" placeholder="e.g., 100px or 50%"></b-form-input>
        </b-form-group>
        <b-form-group label="Height">
          <b-form-input v-model="imageProperties.height" placeholder="e.g., 100px or auto"></b-form-input>
        </b-form-group>
        <b-form-group label="Alignment">
          <b-form-select v-model="imageProperties.alignment">
            <option value="">None</option>
            <option value="ql-align-left">Left</option>
            <option value="ql-align-center">Center</option>
            <option value="ql-align-right">Right</option>
          </b-form-select>
        </b-form-group>
        <b-button variant="primary" @click="insertImage">Insert</b-button>
      </b-form>
    </b-modal>
  </b-card>
</template>

<script>
import {
  BCard,
  BForm,
  BRow,
  BCol,
  BFormGroup,
  BFormTags,
  BButton,
  BFormRadio,
  BFormRadioGroup,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import vSelect from 'vue-select'

import { required, email } from '@validations'
import Ripple from 'vue-ripple-directive'
import { useInputImageRenderer } from '@core/comp-functions/forms/form-utils'
import { ref, reactive, getCurrentInstance } from '@vue/composition-api'
import Vue from 'vue'
import store from '@/store'
import { quillEditor } from 'vue-quill-editor'
import 'quill/dist/quill.snow.css'

// Import Quill and define custom size style
import Quill from 'quill'

const SizeStyle = Quill.import('attributors/style/size')
SizeStyle.whitelist = ['10px', '12px', '14px', '16px', '18px', '20px', '24px', '30px', '32px', '36px', '48px', '60px', '72px', '96px']
Quill.register(SizeStyle, true)

export default {
  setup() {
    const refInputEl = ref(null)
    const messages = ref([])
    const refInputE2 = ref(null)
    const refPreviewEl = ref(null)
    const refPreviewE2 = ref(null)
    
       const refPreviewE3 = ref(null)
      const refInputE3 = ref(null)
    const getCourse = ref(false)
    const durationList = ref([])
    const Tabimage = ref('')
    const coursesList = ref([])
    const coursesListType = ref([])
    const RelatedCourse = ref([])
    const dayContent = ref([])
    const categoriesList = ref([])

    store.dispatch('courses/getAllCoursesRecorded')
      .then(response => {
        coursesList.value = response.data
      })

    const addCourseForm = ref({
    coming_soon:null,
      header_image: '',
      also_avaliable_type: '',
      also_avaliable_id: '',
      category_id: '',
      related_courses: [],
      recorded_course_id: '',
      description: '',
      objectives: '',
      attenders: '',
      outlines:"",
      og_image: '',
      stripe_image:"",
      price: '',
      link_id: '',
      meta_title: '',
      meta_description: '',
      meta_keywords: '',
      alt_header_image: '',
    })

    const form = ref()
    const daycontentArray = ref([])
    const dayContentForm = ref('')

    const editorConfig = ref({
      toolbar: [
        { name: 'save', items: ['savebtn', 'Undo', 'Redo'] },
        { name: 'clipboard', items: ['Cut', 'Copy', 'image|', 'image|Text', 'image|FromWord'] },
        { name: 'document', items: ['Find', 'Replace'] },
        '/',
        { name: 'lists', items: ['NumberedList', 'BulletedList', 'Outdent', 'Indent'] },
        { name: 'insert', items: ['Image', 'Table', 'Smiley', 'SpecialChar'] },
        { name: 'link', items: ['Link', 'Unlink'] },
        '/',
        { name: 'basicstyles', items: ['Font', 'FontSize', 'Bold', 'Italic', 'Underline', 'Strike', 'Subscript', 'Superscript'] },
        { name: 'align', items: ['JustifyLeft', 'JustifyCenter', 'JustifyRight', 'JustifyBlock'] },
      ],
    })

    const getContent = val => {}

    const isLoading = ref(false)
    const removeSpace = target => {
      addCourseForm.value.link_id = target
        .toLowerCase()
        .replace(/\s+/g, '-')
        .replace(/&/g, '')
        .replace(/--/g, '-')
        .replace(/[.,]/g, '-')
        .replace(/[_]/g, '')
        .replace(/[!''"":=]/g, '')
        .replace(/[!?%$^~*#()+]/g, '')
    }

    store.dispatch('categories/AllCategory').then(response => {
      categoriesList.value = response.data.data
    })

    const { inputImageRenderer } = useInputImageRenderer(
      refInputEl,
      base64 => {
        refPreviewEl.value.src = base64
      },
    )
 const { inputImageRendererTab3 } = useInputImageRenderer(
      refInputE3,
  base64 => {
    
    const img = new Image();
    img.src = base64;
    console.log(img)
    img.onload = () => {
      if (img.width !== 200 || img.height !== 200) {
        Vue.swal({
          title: 'Invalid Image Size',
          text: 'Image must be 200x200 pixels.',
          icon: 'error',
          customClass: {
            confirmButton: 'btn btn-primary'
          },
          buttonsStyling: false
        });
        addCourseForm.value.stripe_image = '';
      } else {
        refPreviewE3.value.src = base64;
      }
    };
  },
    )
    const { inputImageRendererTab } = useInputImageRenderer(
      refInputE2,
      base64 => {
        refPreviewE2.value.src = base64
      },
    )

    const addCourseFormvalidate = ref()
    const simple = ref()

    const save = () => {
      addCourseFormvalidate.value.validate().then(success => {
        if (success) {
          const formData = new FormData()
          formData.append('link_id', addCourseForm.value.link_id)
          if (addCourseForm.value.coming_soon) {
            formData.append('coming_soon', addCourseForm.value.coming_soon)          }
            else {
              formData.append('coming_soon', null)          

            }
          
          formData.append('price', addCourseForm.value.price)
          if (addCourseForm.value.also_avaliable_type && addCourseForm.value.also_avaliable_type !== 'null') {
            formData.append('also_avaliable_type', addCourseForm.value.also_avaliable_type)
          }
          if (addCourseForm.value.also_avaliable_id && addCourseForm.value.also_avaliable_id !== 'null') {
            formData.append('also_avaliable_id', addCourseForm.value.also_avaliable_id)
          }
          if (addCourseForm.value.category_id && addCourseForm.value.category_id !== 'null') {
            formData.append('category_id', addCourseForm.value.category_id)
          }
          if (addCourseForm.value.related_courses == null) {
            formData.append('related_courses', JSON.stringify([]))
          } else {
            formData.append('related_courses', JSON.stringify(addCourseForm.value.related_courses))
          }
          formData.append('recorded_course_id', addCourseForm.value.recorded_course_id)
          if (addCourseForm.value.description && addCourseForm.value.description !== 'null') {
            formData.append('description', addCourseForm.value.description)
          }
          if (addCourseForm.value.objectives && addCourseForm.value.objectives !== 'null') {
            formData.append('objectives', addCourseForm.value.objectives)
          }
          if (addCourseForm.value.attenders && addCourseForm.value.attenders !== 'null') {
            formData.append('attenders', addCourseForm.value.attenders)
          }

 if (addCourseForm.value.outlines && addCourseForm.value.outlines !== 'null') {
            formData.append('outlines', addCourseForm.value.outlines)
          }
          
          formData.append('alt_header_image', addCourseForm.value.alt_header_image)
          if (addCourseForm.value.meta_title && addCourseForm.value.meta_title !== 'null') {
            formData.append('meta_title', addCourseForm.value.meta_title)
          }

          if (addCourseForm.value.meta_description && addCourseForm.value.meta_description !== 'null') {
            formData.append('meta_description', addCourseForm.value.meta_description)
          }
          if (addCourseForm.value.meta_keywords && addCourseForm.value.meta_keywords !== 'null') {
            formData.append('meta_keywords', addCourseForm.value.meta_keywords)
          }
          formData.append('og_image', addCourseForm.value.og_image)
          
            formData.append('stripe_image', addCourseForm.value.stripe_image)
          formData.append('header_image', addCourseForm.value.header_image)
          isLoading.value = true
          store.dispatch('content/addCourseRecorded', formData)
            .then(response => {
              isLoading.value = false
              form.value.reset()
              Vue.swal({
                title: 'Course Self learning Added ',
                icon: 'success',
                customClass: {
                  confirmButton: 'btn btn-primary',
                },
                buttonsStyling: false,
              })
              store.dispatch('courses/getAllCoursesRecorded')
                .then(response => {
                  coursesList.value = response.data
                })
              addCourseForm.value = {
                header_image: '',
                recorded_course_id: '',
                description: '',
                objectives: '',
                attenders: '',
                og_image: '',
                price: '',
                outlines:"",
                link_id: '',
                meta_title: '',
                meta_description: '',
                meta_keywords: '',
                alt_header_image: '',
              }
            })
            .catch(error => {
              Vue.swal({
                title: '',
                text: `${error.response.data.message}`,
                icon: 'error',
                confirmButtonText: 'Ok',
                customClass: {
                  confirmButton: 'btn btn-primary',
                },
                buttonsStyling: false,
              })
            })
        }
      })
    }

    function example_image_upload_handler(blobInfo, success, failure, progress) {
      let xhr
      let formData
      const token = localStorage.getItem('token')
      xhr = new XMLHttpRequest()
      xhr.withCredentials = false
      xhr.open('POST', `${process.env.VUE_APP_ROOT_API}/upload_image_tiny_mce`)
      xhr.upload.onprogress = function (e) {
        progress(e.loaded / e.total * 100)
      }
      xhr.setRequestHeader('Authorization', `Bearer ${token}`)
      xhr.onload = function () {
        let json
        if (xhr.status === 403) {
          failure(`HTTP Error: ${xhr.status}`, { remove: true })
          return
        }
        if (xhr.status < 200 || xhr.status >= 300) {
          failure(`HTTP Error: ${xhr.status}`)
          return
        }
        json = JSON.parse(xhr.responseText)
        if (!json || typeof json.location !== 'string') {
          failure(`Invalid JSON: ${xhr.responseText}`)
          return
        }
        success(json.location)
      }
      xhr.onerror = function () {
        failure(`Image upload failed due to a XHR Transport error. Code: ${xhr.status}`)
      }
      formData = new FormData()
      formData.append('image', blobInfo.blob(), blobInfo.filename())
      xhr.send(formData)
    }

    const getDuration = id => {
      const itemFull = coursesList.value.find(el => el.id == id)
      const item = itemFull.content
      if (item) {
        getCoursesByCategory(itemFull.content.category_id)
        getType(itemFull.content.also_avaliable_type)
        addCourseForm.value = itemFull.content
        addCourseForm.value.related_courses = JSON.parse(addCourseForm.value.related_courses)
      } else {
        addCourseForm.value = {
          header_image: '',
          description: '',
          objectives: '',
          attenders: '',
          og_image: '',
          price: '',
          link_id: '',
          meta_title: '',
          meta_description: '',
          meta_keywords: '',
          alt_header_image: '',
        }
        addCourseForm.value.recorded_course_id = id
      }
    }

    const getType = id => {
      if (id == 3) {
        store.dispatch('homepage/getOtherCourse', { id }).then(response => {
          coursesListType.value = response.data.data
        })
      }
      if (id == 2) {
        store.dispatch('courses/getAllCoursesOnline').then(response => {
          coursesListType.value = response.data.data
        })
      }
      if (id == 1) {
        store.dispatch('courses/getAllClassical').then(response => {
          coursesListType.value = response.data.data
        })
      }
    }

    const getCoursesByCategory = id => {
      RelatedCourse.value = []
      store.dispatch('categories/getCoursesByCategory', {
        category_id: id,
      }).then(response => {
        response.data.data.forEach(el => {
          if (el.online == 1) {
            coursesList.value.push({
              id: el.id,
              name: `${el.name} Online`,
              link_id: el.link_id,
              related_courses: el.related_courses,
              attenders: el.attenders,
              objectives: el.objectives,
              days_content: el.days_content,
              description: el.description,
              duration: el.duration,
            })
          } else {
            coursesList.value.push({
              id: el.id,
              name: el.name,
              link_id: el.link_id,
              related_courses: el.related_courses,
              attenders: el.attenders,
              objectives: el.objectives,
              days_content: el.days_content,
              description: el.description,
              duration: el.duration,
            })
          }
        })
        RelatedCourse.value = coursesList.value
      })
    }

    const { proxy } = getCurrentInstance()
      const imageProperties = reactive({
      src: '',
      alt: '',
      width: '',
      height: '',
      alignment: '',
    })
    let editorSelection = null
    let currentEditor = null

    const imageHandler = editorRef => {
      currentEditor = proxy.$refs[editorRef].quill
      editorSelection = currentEditor.getSelection()
      const input = document.createElement('input')
      input.setAttribute('type', 'file')
      input.setAttribute('accept', 'image/*')
      input.click()

      input.onchange = async () => {
        const file = input.files[0]
        const formData = new FormData()
        formData.append('image', file)

        try {
          const token = localStorage.getItem('token')
          const xhr = new XMLHttpRequest()
          xhr.withCredentials = false
          xhr.open('POST', `${process.env.VUE_APP_ROOT_API}/upload_image_tiny_mce`)
          xhr.setRequestHeader('Authorization', `Bearer ${token}`)

          xhr.onload = function () {
            if (xhr.status === 403) {
              console.error(`HTTP Error: ${xhr.status}`)
              return
            }

            if (xhr.status < 200 || xhr.status >= 300) {
              console.error(`HTTP Error: ${xhr.status}`)
              return
            }

            const json = JSON.parse(xhr.responseText)

            if (!json || typeof json.location !== 'string') {
              console.error(`Invalid JSON: ${xhr.responseText}`)
              return
            }

            imageProperties.src = json.location
            proxy.$bvModal.show('image-properties-modal')
          }

          xhr.onerror = function () {
            console.error(`Image upload failed due to a XHR Transport error. Code: ${xhr.status}`)
          }

          xhr.send(formData)
        } catch (error) {
          console.error('Image upload failed:', error)
        }
      }
    }

    const insertImage = () => {
      const editor = currentEditor
    console.log(currentEditor.getSelection())
      const range = editorSelection
      if (!range) {
        console.error('No selection found')
        return
      }
      const { src, alt, width, height, alignment } = imageProperties
  
      editor.insertEmbed(range.index, 'image', { src, alt, width, height, class: alignment })
      proxy.$bvModal.hide('image-modal-post')
         imageProperties.src = '';
      imageProperties.alt = '';
      imageProperties.width = '';
      imageProperties.height = '';
      imageProperties.alignment = '';
    }



    const linkHandler = editorRef => {
      const range = proxy.$refs[editorRef].quill.getSelection()
      if (range) {
        const url = prompt('Enter the URL')
        if (url) {
          const openInNewTab = confirm('Open in new tab?')
          const selectedText = proxy.$refs[editorRef].quill.getText(range)
          const targetAttribute = openInNewTab ? ' target="_blank" rel="noopener noreferrer"' : ''
          const linkValue = `<a href="${url}"${targetAttribute}>${selectedText}</a>`
          proxy.$refs[editorRef].quill.deleteText(range.index, range.length)
          proxy.$refs[editorRef].quill.clipboard.dangerouslyPasteHTML(range.index, linkValue)
        }
      }
    }

    const getEditorOptions = editorRef => ({
      theme: 'snow',
      modules: {
        toolbar: {
          container: [
            [{ header: [1, 2, 3, 4, 5, 6, false] }, { font: [] }],
            [{ list: 'ordered' }, { list: 'bullet' }],
            [{ script: 'sub' }, { script: 'super' }],
            [{ indent: '-1' }, { indent: '+1' }],
            [{ direction: 'rtl' }],
            [{ color: [] }, { background: [] }],
            [{ align: [] }],
            ['link', 'image', 'video'],
            [{ size: SizeStyle.whitelist }],
        
            ['bold', 'italic', 'underline', 'strike'],
            ['clean'],
            ['undo', 'redo'],
            ['paragraph']
          ],
          handlers: {
            image: () => imageHandler(editorRef),
            link: () => linkHandler(editorRef)
          },
        },
      },
      formats: [
        'header', 'font', 'size', 'bold', 'italic', 'underline', 'strike', 'blockquote', 
        'list', 'bullet', 'indent', 'link', 'image', 'video', 'color', 'background', 'align', 
        'direction', 'script'
      ]
    })

    return {
      example_image_upload_handler,
      messages,
      isLoading,
      refInputEl,
      refPreviewEl,
      refPreviewE3,
      refInputE3,
      getDuration,
      getType,
      refInputE2,
      refPreviewE2,
      inputImageRendererTab,
      Tabimage,
      inputImageRendererTab3,
      addCourseForm,
      removeSpace,
      inputImageRenderer,
      addCourseFormvalidate,
      daycontentArray,
      coursesList,
      coursesListType,
      categoriesList,
      getCoursesByCategory,
      durationList,
      simple,
      linkHandler,
       imageHandler,
      insertImage,
      imageProperties,
      getEditorOptions,
      getCourse,
      RelatedCourse,
      save,
      required,
      email,
      dayContent,
      getContent,
      dayContentForm,
      editorConfig,
      form,
      BFormTags,
      
    }
  },

  components: {
    BCard,
    quillEditor,
    ValidationProvider,
    ValidationObserver,
    BForm,
    BRow,
    BCol,
    BButton,
    BFormGroup,
    vSelect,
    BFormRadio,
  BFormRadioGroup,
  },

  directives: {
    Ripple,
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/quill.scss";
@import "@core/scss/vue/pages/page-blog.scss";
</style>
<style>
.quill-editor {
  margin-left: 1px;
  width: 100%;
}
</style>
<style>
.mb-2.col-12 {
  margin: -1px !important;
}
.border.rounded.p-2 {
  margin: 8px 1px !important;
}
.col-12 {
  margin: 0px;
}
</style>
